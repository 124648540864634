.practice {
  background: linear-gradient(
      to top,
      rgba(13, 13, 13, 1),
      rgba(13, 13, 13, 0.6),
      rgba(13, 13, 13, 0.6),
      rgba(13, 13, 13, 1)
    ),
    url('../img/practice.webp');
  background-size: cover;
  background-position: bottom;
}

.wrapper-practice {
  position: relative;
  text-align: center;

  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: space-between;
    align-self: center;
    height: 350px;
  }
}

.description-practice {
  @media screen and (min-width: 1280px) {
    text-align: start;
  }
}

.practice-img {
  display: none;
  @media screen and (min-width: 1280px) {
    display: block;
    width: 613px;
    position: absolute;
    right: 0;
  }
}
