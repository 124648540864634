.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding: 12px 0;
  z-index: 2;
  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.header-bg {
  background-color: rgba(0, 0, 0, .9);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 30px;
}

.header-logo {}

.header-oder-list {
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    gap: 30px;
  }
}

.order-item {}

.order-btn {
  border: none;
  background-color: transparent;
  color: var(--additional-text-color);
}

.header-contacts {
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header-tel,
.footer-tel {
  font-style: 18px;
  font-weight: 900;
}

.header-location,
.footer-location {
  font-style: 14px;
  color: var(--additional-text-color);
}

.logo {
  width: 150px;
}