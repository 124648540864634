.map-iframe {
  width: 100%;
  height: 400px;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.inner-map {
  position: relative;
}

.around-map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(to top,
      rgba(13, 13, 13, 1),
      rgba(13, 13, 13, 0.3),
      rgba(13, 13, 13, 0.3),
      rgba(13, 13, 13, 1));
  pointer-events: none;
}

.about {
  width: 300px;
  height: 300px;
  background-color: #131313;
  border-bottom-left-radius: 800px;
  position: absolute;
  top: -156px;
  right: -212px;
  transition: all 250ms linear;
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  &-inner-container {
    opacity: 0;
  }

  &-text {
    margin-bottom: 10px;
  }

  &-title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  &-text-accent {
    text-transform: uppercase;
    color: var(--accent-color);
  }

  &-link {
    transition: border-bottom 250ms linear;

    &:hover,
    &:focus {
      border-bottom: 1px solid var(--accent-color);
    }
  }

  &-active {
    width: 300px;
    height: 300px;
    background-color: #131313;
    border-bottom-left-radius: 300px;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 250ms linear;
    text-align: center;

    padding-top: 30px;
    display: flex;
    flex-direction: column;

    &-inner-container {
      opacity: 1;
    }
  }
}

.btn-phone {
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 20px;
  background-color: #131313;
  border: none;
  outline: none;
  border-radius: 50px;
}

.btn-phone .img-phone {
  width: 50px;
}