/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */


@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url('../fonts/ProximaNovaCond-Regular.eot');
  src: local('Proxima Nova Condensed Regular'), local('ProximaNovaCond-Regular'),
    url('../fonts/ProximaNovaCond-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/ProximaNovaCond-Regular.woff') format('woff'),
    url('../fonts/ProximaNovaCond-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Cn Bl';
  src: url('../fonts/ProximaNovaCond-Black.eot');
  src: local('Proxima Nova Condensed Black'), local('ProximaNovaCond-Black'),
    url('../fonts/ProximaNovaCond-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNovaCond-Black.woff') format('woff'),
    url('../fonts/ProximaNovaCond-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
    url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNova-Regular.woff') format('woff'),
    url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Bl';
  src: url('../fonts/ProximaNova-Black.eot');
  src: local('Proxima Nova Black'), local('ProximaNova-Black'),
    url('../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNova-Black.woff') format('woff'),
    url('../fonts/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
    url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNova-Bold.woff') format('woff'),
    url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url('../fonts/ProximaNovaExCn-Bold.eot');
  src: local('Proxima Nova Extra Condensed Bold'), local('ProximaNovaExCn-Bold'),
    url('../fonts/ProximaNovaExCn-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNovaExCn-Bold.woff') format('woff'),
    url('../fonts/ProximaNovaExCn-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url('../fonts/ProximaNovaCond-Bold.eot');
  src: local('Proxima Nova Condensed Bold'), local('ProximaNovaCond-Bold'),
    url('../fonts/ProximaNovaCond-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNovaCond-Bold.woff') format('woff'),
    url('../fonts/ProximaNovaCond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova ExCn Bl';
  src: url('../fonts/ProximaNovaExCn-Black.eot');
  src: local('Proxima Nova Extra Condensed Black'),
    local('ProximaNovaExCn-Black'),
    url('../fonts/ProximaNovaExCn-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNovaExCn-Black.woff') format('woff'),
    url('../fonts/ProximaNovaExCn-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url('../fonts/ProximaNovaExCn-Regular.eot');
  src: local('Proxima Nova Extra Condensed Regular'),
    local('ProximaNovaExCn-Regular'),
    url('../fonts/ProximaNovaExCn-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/ProximaNovaExCn-Regular.woff') format('woff'),
    url('../fonts/ProximaNovaExCn-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

