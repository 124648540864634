.container-cost {
  text-align: center;
}

.wrapper-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best-cost {
  display: none;

  @media screen and (min-width: 1280px) {
    display: block;
    margin-left: -94px;
    font-size: 45px;
    font-weight: bold;
    color: var(--accent-color);
    transform: rotate(270deg);
  }
}

.inner-description-cost {
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: start;
  }
}

.img-coins {
  display: none;

  @media screen and (min-width: 1280px) {
    display: block;
    width: 300px;
  }
}

.cost-text {
  font-weight: 700;
  color: var(--accent-color);
}
