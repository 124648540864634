.credit-section {
  background: linear-gradient(
      to top,
      rgba(13, 13, 13, 1),
      rgba(13, 13, 13, 0.7),
      rgba(13, 13, 13, 0.7),
      rgba(13, 13, 13, 1)
    ),
    url('../img/credit.jpg');
  background-size: cover;
  background-position: center;
}

.information-container {
  text-align: center;
}

.wrapper-credit {
  text-align: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.pre-title-credit {
  font-size: 16pt;
  text-align: start;
  color: var(--accent-color);
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    width: 300px;
    height: 100px;
    text-align: center;
    margin-top: 80px;
    margin-left: -110px;
    font-weight: bold;
    text-align: center;
    font-size: 28px;
    transform: rotate(-90deg);
  }

  @media screen and (min-width: 1280px) {
    width: 341px;
    height: 200px;
    text-align: center;
    margin-top: 30px;
    margin-left: -94px;
    font-size: 32px;
    font-weight: bold;
    transform: rotate(-90deg);
  }
}

.credit-information {
  @media screen and (min-width: 768px) {
    width: 700px;
    margin-top: 50px;
    margin-right: 170px;
  }

  @media screen and (min-width: 1280px) {
    margin-right: 0;
    margin-left: 22px;
  }
}

.credit-information-title {
  margin-bottom: 25px;
  font-size: 18px;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }

  @media screen and (min-width: 1280px) {
  }
}

.credit-information-pre {
  margin-bottom: 25px;
}

.information-btn {
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    margin-top: 55px;
  }
}
