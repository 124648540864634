.hero {
  padding: 110px 0 80px;
  background: linear-gradient(
      to top,
      rgba(13, 13, 13, 1),
      rgba(13, 13, 13, 0.6),
      rgba(13, 13, 13, 0.6),
      rgba(13, 13, 13, 1)
    ),
    url('../img/mountains.webp');
  background-size: cover;
  background-position: center;

  @media screen and (min-width: 768px) {
    padding: 130px 0 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 90px 0;
    background-position: top;
  }
}

.hero-container {
  @media screen and (min-width: 768px) {
    // display: flex;
    // justify-content: space-between;
  }

  @media screen and (min-width: 1280px) {
  }
}

.hero-main-wrap {
  margin-bottom: 130px;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 110px;
  }
}

.hero-left-side-wrap {
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1280px) {
    padding-top: 130px;
  }
}

.hero-title {
  font-size: 22px;
  font-weight: 700;
  line-height: calc(26 / 22);

  @media screen and (min-width: 768px) {
    font-size: 34px;
    max-width: 420px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 60px;
    max-width: 955px;
  }
}

.hero-title-accent {
  color: var(--accent-color);
}

.hero-text {
  // font-size: 16px;
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1280px) {
  }
}

.hero-text-bold {
  font-size: 16px;
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 30px;
  }
}

.hero-button {
  display: block;
  margin: 30px auto 0;
  min-height: 45px;
  background-color: #AA2222;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px 35px;
  font-size: 16px;
  transition: color 0.25s linear, background-color 0.25s linear;

  @media screen and (min-width: 768px) {
    margin: 30px 0 0;
  }

  @media screen and (min-width: 1280px) {
    margin: 116px 0 0;
  }
}

.hero-right-side-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.hero-img-number {
  width: 100px;

  @media screen and (min-width: 768px) {
    width: 165px;
  }

  @media screen and (min-width: 1280px) {
    width: 300px;
  }
}

.hero-img-week {
  width: 14px;

  @media screen and (min-width: 768px) {
    width: 24px;
  }

  @media screen and (min-width: 1280px) {
    width: 56px;
  }
}

.hero-img-car {
  width: 320px;
  position: absolute;
  top: 100px;

  @media screen and (min-width: 768px) {
    min-width: 450px;
    top: 120px;
    right: -40px;
  }

  @media screen and (min-width: 1280px) {
    min-width: 760px;
    top: 259px;
  }
}

.hero-descr-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; //ось це я додав
  gap: 10;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1280px) {
    justify-content: center;
    gap: 50px;
  }
}

.hero-descr-item {
  display: flex;
  font-weight: 700;
}

.hero-descr-item::before {
  content: url(../img/check.png);
  // content: '✅';
  display: block;
  margin-right: 5px;
  width: 20px;
  // background-image: url(../img/check.png);
}

.btn-to-top {
  width: 32px;
  height: 32px;
  position: fixed;
  z-index: 5;
  right: 20px;
  bottom: 20px;
  background-color: var(--accent-color);
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: 768px) {
    width: 44px;
    height: 44px;
  }

  @media screen and (min-width: 1280px) {
  }

  &:hover,
  &:focus {
    background-color: #fff;
  }

  &:hover .hero__row-svg,
  &:focus .hero__row-svg,
  &:active .hero__row-svg {
    fill: var(--accent-color);
  }
}

.btn-to-top.showBtn {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.hero__row-svg {
  width: 100%;
  height: 100%;
  fill: #fff;
  transition-property: fill;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
