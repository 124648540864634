@import '~node_modules/modern-normalize/modern-normalize.css';
@import './fonts';
@import './reset';
@import './base';
@import './header';
@import './hero';
@import './category';
@import './cost';
@import './schedule';
@import './practice';
@import './exam';
@import './credit';
@import './map';
@import './footer';
@import './modal-order';
@import './modal-success';
