.exam-section {
  background: linear-gradient(
      to top,
      rgba(13, 13, 13, 1),
      rgba(13, 13, 13, 0.8),
      rgba(13, 13, 13, 0.8),
      rgba(13, 13, 13, 1)
    ),
    url('../img/avtoshkola.webp');
  background-size: cover;
  background-position: center;

  @media screen and (min-width: 1280px) {
    background-position: 50%;
  }
}

.wrapper-exam {
  text-align: center;
}

.pre-section-title {
  font-weight: 400;
}

.info-exam {
  font-size: 16px;
  line-height: 1.42;
  text-align: start;
  margin-bottom: 20px;
}

.info-accent {
  font-weight: 700;
  color: var(--accent-color);
}

.additional-text-exam {
  text-align: center;
  padding-top: 50px;
  font-size: 20px;
  line-height: 1.42;

  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.exam-additional {
  height: 400px;
  position: relative;
  text-align: center;
  font-size: 28px;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.info-accent-exam {
  font-size: 36px;

  @media screen and (min-width: 768px) {
    font-size: 38px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 52px;
  }
}

.enter-text {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.car-img-left {
  position: absolute;
  width: 280px;
  bottom: 25px;
  left: -111px;

  @media screen and (min-width: 768px) {
    width: 500px;
    bottom: 0;
    left: -170px;
  }

  @media screen and (min-width: 1280px) {
    width: 500px;
    bottom: -30px;
    left: -90px;
  }
}

.car-img-right {
  width: 260px;
  position: absolute;
  bottom: 28px;
  right: -100px;

  @media screen and (min-width: 768px) {
    width: 500px;
    bottom: 0;
    right: -180px;
  }

  @media screen and (min-width: 1280px) {
    width: 498px;
    bottom: -22px;
    right: -100px;
  }
}

.exam-btn-mobile {
  @media screen and (min-width: 767px) {
    display: none;
  }
}

.exam-btn-tablet {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
