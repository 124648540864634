:root {
  --main-text-color: #f5f4f4;
  --secondary-text-color: rgb(13, 13, 13);
  --accent-color: #d43232;
  // --accent-color: #fb1b14;
  --main-bg-color: rgb(13, 13, 13);
  --additional-text-color: #ccc7c7;
  --button-bg-color: #1db428;
}

body {
  font-family: 'Proxima Nova Rg', sans-serif;
  font-size: 16px;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.accent {
  color: var(--accent-color);
}

.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (min-width: 375px) {
    width: 375px;
  }

  @media screen and (min-width: 768px) {
    min-width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media screen and (min-width: 1280px) {
    width: 1280px;
  }
}

.section {
  overflow: hidden;
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    margin-top: 60px;
    padding: 30px 0;
  }

  @media screen and (min-width: 1280px) {
    margin-top: 110px;
    padding: 50px 0;
  }
}

.section-title {
  margin-bottom: 30px;
  font-size: 28px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    font-size: 40px;
    line-height: 1.2;
  }

  @media screen and (min-width: 1280px) {
    font-size: 50px;
  }
}

.description-list {
  font-size: 16px;
  list-style: none;
  color: var(--main-text-color);
  text-align: start;
}

.description-item {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 16px;

  &::before {
    content: '📌';
    position: absolute;
    left: -5px;
    top: 9px;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 768px) {
    margin-left: 30px;
  }
}

.call-btn {
  padding: 10px 35px;
  font-weight: 700;
  font-size: 16px;
  color: var(--main-text-color);
  background-color: var(--accent-color);
  text-align: center;
  text-transform: uppercase;
  border: none;
  outline: none;
  border-radius: 5px;
  transition: color 250ms linear, background-color 250ms linear;
  margin-top: 50px;

  &:hover,
  &:focus {
    background-color: var(--main-text-color);
    color: var(--secondary-text-color);
  }
}

.accent-bold-text {
  color: var(--accent-color);
  font-weight: 700;
}

.social-list {
  display: flex;
  justify-content: flex-end;

  &-item {
    margin-top: 20px;
    margin-right: 30px;
    width: 50px;
  }
  &-item:nth-of-type(2) {
    width: 60px;
    margin-top: 50px;
  }
}
