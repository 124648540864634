/*backdrop and modal*/
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;

  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1),
    visibility 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.is-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 400px;
  // height: 80%;
  padding: 48px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    45deg,
    rgba(116, 115, 131, 0.8) 0%,
    rgba(255, 255, 255, 0.8) 100%,
    rgba(0, 212, 255, 0.8) 100%
  );
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  border: 2px solid rgb(0, 0, 0);
  color: var(--secondary-text-color);
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1),
    visibility 500ms cubic-bezier(0.4, 0, 0.2, 1);
  .is-hidden & {
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
  }
}

.form {
  width: 100%;
}

.modal__title {
  font-size: 22px;
  text-align: center;
  padding: 8px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 50px;
}

.modal__text {
  font-size: 30px;
  margin-bottom: 50px;
  padding: 10px;
}

.close-modal-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border-color: #666666;
  transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid #666666;

  cursor: pointer;
}

.input-wrap {
  position: relative;
  width: 100%;
}

.input-wrap {
  margin-bottom: 30px;
}

.modal-label {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal-input {
  display: block;
  padding: 8px 8px 8px 16px;
  width: 100%;
  border-radius: 5px;
  border-color: transparent;
  outline: none;
}

.modal-input:focus ~ .modal-label,
.modal-input:not(:placeholder-shown) ~ .modal-label {
  transform: translateY(-44px) translateX(-16px) scale(0.9);
}

.modal-submit-btn.call-btn {
  display: block;
  width: 100%;
  padding: 8px;
  border-color: transparent;
  outline: none;
  margin: 0;
}

.close-modal-btn:hover {
  transform: rotate(180deg);
}

.close-modal-btn::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 9px;
  display: block;
  width: 2px;
  height: 16px;
  background-color: #666666;
  transform: rotate(45deg);
  border-radius: 5px;
}

.close-modal-btn::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 9px;
  display: block;
  width: 2px;
  height: 16px;
  background-color: #666666;
  transform: rotate(-45deg);
  border-radius: 5px;
}

.modal-submit-btn-call.call-btn {
  background-color: #0c9b07;
  color: var(--secondary-text-color);
}

.modal-submit-btn-call.call-btn:hover {
  background-color: #11f309;
}
