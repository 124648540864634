*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

@font-face {
  font-family: Proxima Nova Cn Rg;
  src: url("ProximaNovaCond-Regular.422c343b.eot");
  src: local(Proxima Nova Condensed Regular), local(ProximaNovaCond-Regular), url("ProximaNovaCond-Regular.422c343b.eot#iefix") format("embedded-opentype"), url("ProximaNovaCond-Regular.85e7b6f6.woff") format("woff"), url("ProximaNovaCond-Regular.3478314b.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova Cn Bl;
  src: url("ProximaNovaCond-Black.1b21089e.eot");
  src: local(Proxima Nova Condensed Black), local(ProximaNovaCond-Black), url("ProximaNovaCond-Black.1b21089e.eot#iefix") format("embedded-opentype"), url("ProximaNovaCond-Black.a26a76c7.woff") format("woff"), url("ProximaNovaCond-Black.ab980a36.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova Rg;
  src: url("ProximaNova-Regular.131519b6.eot");
  src: local(Proxima Nova Regular), local(ProximaNova-Regular), url("ProximaNova-Regular.131519b6.eot#iefix") format("embedded-opentype"), url("ProximaNova-Regular.9ca2cacf.woff") format("woff"), url("ProximaNova-Regular.21320d14.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova Bl;
  src: url("ProximaNova-Black.90d8ece5.eot");
  src: local(Proxima Nova Black), local(ProximaNova-Black), url("ProximaNova-Black.90d8ece5.eot#iefix") format("embedded-opentype"), url("ProximaNova-Black.76ce897e.woff") format("woff"), url("ProximaNova-Black.acf12d88.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Proxima Nova Rg;
  src: url("ProximaNova-Bold.6dba70cd.eot");
  src: local(Proxima Nova Bold), local(ProximaNova-Bold), url("ProximaNova-Bold.6dba70cd.eot#iefix") format("embedded-opentype"), url("ProximaNova-Bold.7e04a80d.woff") format("woff"), url("ProximaNova-Bold.0fd5f275.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova ExCn Rg;
  src: url("ProximaNovaExCn-Bold.dde1c7db.eot");
  src: local(Proxima Nova Extra Condensed Bold), local(ProximaNovaExCn-Bold), url("ProximaNovaExCn-Bold.dde1c7db.eot#iefix") format("embedded-opentype"), url("ProximaNovaExCn-Bold.99c2ec93.woff") format("woff"), url("ProximaNovaExCn-Bold.8642cd1c.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova Cn Rg;
  src: url("ProximaNovaCond-Bold.5b907fa8.eot");
  src: local(Proxima Nova Condensed Bold), local(ProximaNovaCond-Bold), url("ProximaNovaCond-Bold.5b907fa8.eot#iefix") format("embedded-opentype"), url("ProximaNovaCond-Bold.1c9c9a45.woff") format("woff"), url("ProximaNovaCond-Bold.9750e70e.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova ExCn Bl;
  src: url("ProximaNovaExCn-Black.59c30b85.eot");
  src: local(Proxima Nova Extra Condensed Black), local(ProximaNovaExCn-Black), url("ProximaNovaExCn-Black.59c30b85.eot#iefix") format("embedded-opentype"), url("ProximaNovaExCn-Black.fe556924.woff") format("woff"), url("ProximaNovaExCn-Black.2d4f87e3.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Proxima Nova ExCn Rg;
  src: url("ProximaNovaExCn-Regular.1b8194cd.eot");
  src: local(Proxima Nova Extra Condensed Regular), local(ProximaNovaExCn-Regular), url("ProximaNovaExCn-Regular.1b8194cd.eot#iefix") format("embedded-opentype"), url("ProximaNovaExCn-Regular.a92846ec.woff") format("woff"), url("ProximaNovaExCn-Regular.23163301.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

:root {
  --main-text-color: #f5f4f4;
  --secondary-text-color: #0d0d0d;
  --accent-color: #d43232;
  --main-bg-color: #0d0d0d;
  --additional-text-color: #ccc7c7;
  --button-bg-color: #1db428;
}

body {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-family: Proxima Nova Rg, sans-serif;
  font-size: 16px;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.accent {
  color: var(--accent-color);
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

@media screen and (min-width: 375px) {
  .container {
    width: 375px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    min-width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1280px;
  }
}

.section {
  margin-bottom: 60px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .section {
    margin-top: 60px;
    padding: 30px 0;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    margin-top: 110px;
    padding: 50px 0;
  }
}

.section-title {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .section-title {
    font-size: 40px;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1280px) {
  .section-title {
    font-size: 50px;
  }
}

.description-list {
  color: var(--main-text-color);
  font-size: 16px;
  list-style: none;
}

.description-list:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.description-list:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.description-item {
  margin-bottom: 10px;
  padding-left: 20px;
  font-size: 16px;
  position: relative;
}

.description-item:before {
  content: "📌";
  position: absolute;
  top: 9px;
  left: -5px;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .description-item {
    margin-left: 30px;
  }
}

.call-btn {
  color: var(--main-text-color);
  background-color: var(--accent-color);
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 50px;
  padding: 10px 35px;
  font-size: 16px;
  font-weight: 700;
  transition: color .25s linear, background-color .25s linear;
}

.call-btn:hover, .call-btn:focus {
  background-color: var(--main-text-color);
  color: var(--secondary-text-color);
}

.accent-bold-text {
  color: var(--accent-color);
  font-weight: 700;
}

.social-list {
  justify-content: flex-end;
  display: flex;
}

.social-list-item {
  width: 50px;
  margin-top: 20px;
  margin-right: 30px;
}

.social-list-item:nth-of-type(2) {
  width: 60px;
  margin-top: 50px;
}

.header {
  height: 80px;
  z-index: 2;
  padding: 12px 0;
  transition: background-color .5s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header-bg {
  background-color: rgba(0, 0, 0, .9);
}

.header-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.logo {
  font-size: 30px;
}

.header-oder-list {
  display: none;
}

@media screen and (min-width: 768px) {
  .header-oder-list {
    gap: 30px;
    display: flex;
  }
}

.order-btn {
  color: var(--additional-text-color);
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.header-contacts {
  flex-direction: column;
  gap: 10px;
  font-style: normal;
  display: flex;
}

.header-tel, .footer-tel {
  font-style: 18px;
  font-weight: 900;
}

.header-location, .footer-location {
  font-style: 14px;
  color: var(--additional-text-color);
}

.logo {
  width: 150px;
}

.hero {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to top, #0d0d0d, rgba(13, 13, 13, .6), rgba(13, 13, 13, .6), #0d0d0d), url("mountains.41d3dbae.webp");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  padding: 110px 0 80px;
}

@media screen and (min-width: 768px) {
  .hero {
    padding: 130px 0 0;
  }
}

@media screen and (min-width: 1280px) {
  .hero {
    background-position: top;
    padding: 90px 0;
  }
}

.hero-main-wrap {
  margin-bottom: 130px;
}

@media screen and (min-width: 768px) {
  .hero-main-wrap {
    justify-content: space-between;
    margin-bottom: 80px;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .hero-main-wrap {
    margin-bottom: 110px;
  }
}

.hero-left-side-wrap {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .hero-left-side-wrap {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .hero-left-side-wrap {
    padding-top: 130px;
  }
}

.hero-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.18182;
}

@media screen and (min-width: 768px) {
  .hero-title {
    max-width: 420px;
    font-size: 34px;
  }
}

@media screen and (min-width: 1280px) {
  .hero-title {
    max-width: 955px;
    font-size: 60px;
  }
}

.hero-title-accent {
  color: var(--accent-color);
}

@media screen and (min-width: 768px) {
  .hero-text {
    font-size: 20px;
  }
}

.hero-text-bold {
  font-size: 16px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .hero-text-bold {
    font-size: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .hero-text-bold {
    font-size: 30px;
  }
}

.hero-button {
  min-height: 45px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #a22;
  border: none;
  border-radius: 5px;
  outline: none;
  margin: 30px auto 0;
  padding: 10px 35px;
  font-size: 16px;
  transition: color .25s linear, background-color .25s linear;
  display: block;
}

@media screen and (min-width: 768px) {
  .hero-button {
    margin: 30px 0 0;
  }
}

@media screen and (min-width: 1280px) {
  .hero-button {
    margin: 116px 0 0;
  }
}

.hero-right-side-wrap {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.hero-img-number {
  width: 100px;
}

@media screen and (min-width: 768px) {
  .hero-img-number {
    width: 165px;
  }
}

@media screen and (min-width: 1280px) {
  .hero-img-number {
    width: 300px;
  }
}

.hero-img-week {
  width: 14px;
}

@media screen and (min-width: 768px) {
  .hero-img-week {
    width: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .hero-img-week {
    width: 56px;
  }
}

.hero-img-car {
  width: 320px;
  position: absolute;
  top: 100px;
}

@media screen and (min-width: 768px) {
  .hero-img-car {
    min-width: 450px;
    top: 120px;
    right: -40px;
  }
}

@media screen and (min-width: 1280px) {
  .hero-img-car {
    min-width: 760px;
    top: 259px;
  }
}

.hero-descr-list {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .hero-descr-list {
    flex-flow: wrap;
    justify-content: center;
    gap: 30px;
  }
}

@media screen and (min-width: 1280px) {
  .hero-descr-list {
    justify-content: center;
    gap: 50px;
  }
}

.hero-descr-item {
  font-weight: 700;
  display: flex;
}

.hero-descr-item:before {
  content: url("check.d2089b45.png");
  width: 20px;
  margin-right: 5px;
  display: block;
}

.btn-to-top {
  width: 32px;
  height: 32px;
  z-index: 5;
  background-color: var(--accent-color);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition-property: opacity, visibility;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

@media screen and (min-width: 768px) {
  .btn-to-top {
    width: 44px;
    height: 44px;
  }
}

.btn-to-top:hover, .btn-to-top:focus {
  background-color: #fff;
}

.btn-to-top:hover .hero__row-svg, .btn-to-top:focus .hero__row-svg, .btn-to-top:active .hero__row-svg {
  fill: var(--accent-color);
}

.btn-to-top.showBtn {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.hero__row-svg {
  width: 100%;
  height: 100%;
  fill: #fff;
  transition-property: fill;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.category-section {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to top, #0d0d0d, rgba(13, 13, 13, .8), rgba(13, 13, 13, .8), rgba(13, 13, 13, .9), #0d0d0d), url("fist.bb299fc9.webp");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.section-title {
  text-align: center;
}

.wrapper-category {
  flex-direction: column-reverse;
  display: flex;
}

@media screen and (min-width: 768px) {
  .wrapper-category {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 80px;
    display: flex;
  }
}

.inner-category {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  display: flex;
}

.category-title {
  margin-bottom: 30px;
  font-size: 28px;
}

.category-list {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.category-tem:not(:last-child) {
  margin-right: 10px;
}

.category-text {
  color: var(--accent-color);
  font-size: 50px;
  font-weight: 700;
  line-height: 1.18182;
}

.container-cost {
  text-align: center;
}

.wrapper-cost {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.best-cost {
  display: none;
}

@media screen and (min-width: 1280px) {
  .best-cost {
    color: var(--accent-color);
    margin-left: -94px;
    font-size: 45px;
    font-weight: bold;
    display: block;
    transform: rotate(270deg);
  }
}

.inner-description-cost {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .inner-description-cost:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .inner-description-cost:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

.img-coins {
  display: none;
}

@media screen and (min-width: 1280px) {
  .img-coins {
    width: 300px;
    display: block;
  }
}

.cost-text {
  color: var(--accent-color);
  font-weight: 700;
}

.schedule-section {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to top, #0d0d0d, rgba(13, 13, 13, .6), rgba(13, 13, 13, .6), #0d0d0d), url("class.e594092f.webp");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.pre-title-schedule {
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .pre-title-schedule {
    font-size: 28px;
  }
}

.schedule-list {
  height: 700px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .schedule-list {
    height: 500px;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 10px;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .schedule-list {
    height: 300px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.schedule-item {
  width: 300px;
  height: 180px;
  opacity: .8;
  cursor: pointer;
  background: linear-gradient(45deg, #747383 0%, #fff 100%, #00d4ff 100%);
  border-radius: 20px;
  justify-content: center;
  justify-self: center;
  transition: all .25s linear;
  display: flex;
  position: relative;
}

.schedule-item:hover {
  transform: scale(1.05);
}

@media screen and (min-width: 768px) {
  .schedule-item {
    margin: auto;
  }
}

.title-block {
  color: var(--secondary-text-color);
  text-align: center;
  background-color: #fb6f58;
  border-radius: 20px;
  margin-bottom: auto;
  padding: 20px 30px;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: -15px;
}

.day-list {
  color: var(--secondary-text-color);
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.day-item {
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  border-radius: 5px;
  padding: 8px;
  font-size: 20px;
}

.practice {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to top, #0d0d0d, rgba(13, 13, 13, .6), rgba(13, 13, 13, .6), #0d0d0d), url("practice.a6865719.webp");
  background-position: bottom;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.wrapper-practice {
  text-align: center;
  position: relative;
}

@media screen and (min-width: 1280px) {
  .wrapper-practice {
    height: 350px;
    justify-content: space-between;
    align-self: center;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .description-practice:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .description-practice:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

.practice-img {
  display: none;
}

@media screen and (min-width: 1280px) {
  .practice-img {
    width: 613px;
    display: block;
    position: absolute;
    right: 0;
  }
}

.exam-section {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to top, #0d0d0d, rgba(13, 13, 13, .8), rgba(13, 13, 13, .8), #0d0d0d), url("avtoshkola.6eb78e5f.webp");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

@media screen and (min-width: 1280px) {
  .exam-section {
    background-position: 50%;
  }
}

.wrapper-exam {
  text-align: center;
}

.pre-section-title {
  font-weight: 400;
}

.info-exam {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.42;
}

.info-exam:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.info-exam:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.info-accent {
  color: var(--accent-color);
  font-weight: 700;
}

.additional-text-exam {
  text-align: center;
  padding-top: 50px;
  font-size: 20px;
  line-height: 1.42;
}

@media screen and (min-width: 1280px) {
  .additional-text-exam {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

.exam-additional {
  height: 400px;
  text-align: center;
  font-size: 28px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .exam-additional {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.info-accent-exam {
  font-size: 36px;
}

@media screen and (min-width: 768px) {
  .info-accent-exam {
    font-size: 38px;
  }
}

@media screen and (min-width: 1280px) {
  .info-accent-exam {
    font-size: 52px;
  }
}

.enter-text {
  display: none;
}

@media screen and (min-width: 768px) {
  .enter-text {
    display: block;
  }
}

.car-img-left {
  width: 280px;
  position: absolute;
  bottom: 25px;
  left: -111px;
}

@media screen and (min-width: 768px) {
  .car-img-left {
    width: 500px;
    bottom: 0;
    left: -170px;
  }
}

@media screen and (min-width: 1280px) {
  .car-img-left {
    width: 500px;
    bottom: -30px;
    left: -90px;
  }
}

.car-img-right {
  width: 260px;
  position: absolute;
  bottom: 28px;
  right: -100px;
}

@media screen and (min-width: 768px) {
  .car-img-right {
    width: 500px;
    bottom: 0;
    right: -180px;
  }
}

@media screen and (min-width: 1280px) {
  .car-img-right {
    width: 498px;
    bottom: -22px;
    right: -100px;
  }
}

@media screen and (min-width: 767px) {
  .exam-btn-mobile {
    display: none;
  }
}

.exam-btn-tablet {
  display: none;
}

@media screen and (min-width: 768px) {
  .exam-btn-tablet {
    display: block;
  }
}

.credit-section {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to top, #0d0d0d, rgba(13, 13, 13, .7), rgba(13, 13, 13, .7), #0d0d0d), url("credit.a890781f.jpg");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.information-container {
  text-align: center;
}

.wrapper-credit {
  text-align: center;
  height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .wrapper-credit {
    display: flex;
  }
}

.pre-title-credit {
  color: var(--accent-color);
  margin-bottom: 30px;
  font-size: 16pt;
}

.pre-title-credit:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.pre-title-credit:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .pre-title-credit {
    width: 300px;
    height: 100px;
    text-align: center;
    text-align: center;
    margin-top: 80px;
    margin-left: -110px;
    font-size: 28px;
    font-weight: bold;
    transform: rotate(-90deg);
  }
}

@media screen and (min-width: 1280px) {
  .pre-title-credit {
    width: 341px;
    height: 200px;
    text-align: center;
    margin-top: 30px;
    margin-left: -94px;
    font-size: 32px;
    font-weight: bold;
    transform: rotate(-90deg);
  }
}

@media screen and (min-width: 768px) {
  .credit-information {
    width: 700px;
    margin-top: 50px;
    margin-right: 170px;
  }
}

@media screen and (min-width: 1280px) {
  .credit-information {
    margin-left: 22px;
    margin-right: 0;
  }
}

.credit-information-title {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .credit-information-title {
    font-size: 24px;
  }
}

.credit-information-pre {
  margin-bottom: 25px;
}

.information-btn {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .information-btn {
    margin-top: 55px;
  }
}

.map-iframe {
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 767px) {
  .map-iframe {
    display: none;
  }
}

.inner-map {
  position: relative;
}

.around-map {
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(to top, #0d0d0d, rgba(13, 13, 13, .3), rgba(13, 13, 13, .3), #0d0d0d);
  position: absolute;
  top: 0;
}

.about {
  width: 300px;
  height: 300px;
  background-color: #131313;
  border-bottom-left-radius: 800px;
  flex-direction: column;
  padding-top: 30px;
  transition: all .25s linear;
  display: flex;
  position: absolute;
  top: -156px;
  right: -212px;
}

.about-inner-container {
  opacity: 0;
}

.about-text {
  margin-bottom: 10px;
}

.about-title {
  margin-bottom: 10px;
  font-size: 22px;
}

.about-text-accent {
  text-transform: uppercase;
  color: var(--accent-color);
}

.about-link {
  transition: border-bottom .25s linear;
}

.about-link:hover, .about-link:focus {
  border-bottom: 1px solid var(--accent-color);
}

.about-active {
  width: 300px;
  height: 300px;
  text-align: center;
  background-color: #131313;
  border-bottom-left-radius: 300px;
  flex-direction: column;
  padding-top: 30px;
  transition: all .25s linear;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.about-active-inner-container {
  opacity: 1;
}

.btn-phone {
  background-color: #131313;
  border: none;
  border-radius: 50px;
  outline: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 20px;
}

.btn-phone .img-phone {
  width: 50px;
}

.footer {
  background-color: var(--main-bg-color);
  padding: 30px 0;
}

.footer-container {
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.footer-wrap {
  display: none;
}

@media screen and (min-width: 768px) {
  .footer-wrap {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.footer-right-wrap {
  align-items: center;
  gap: 60px;
  display: flex;
}

.footer-contacts {
  flex-direction: column;
  display: flex;
}

.footer-tel {
  margin-bottom: 10px;
}

.footer-oder-list {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.footer-text {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .footer-text {
    font-size: 20px;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 1;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity .5s cubic-bezier(.4, 0, .2, 1), visibility .5s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.is-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.modal {
  width: 95%;
  max-width: 400px;
  opacity: 1;
  color: var(--secondary-text-color);
  background: linear-gradient(45deg, rgba(116, 115, 131, .8) 0%, rgba(255, 255, 255, .8) 100%, rgba(0, 212, 255, .8) 100%);
  border: 2px solid #000;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 20px;
  transition: opacity .5s cubic-bezier(.4, 0, .2, 1), visibility .5s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.is-hidden .modal {
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.form {
  width: 100%;
}

.modal__title {
  text-align: center;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 8px;
  font-size: 22px;
}

.modal__text {
  margin-bottom: 50px;
  padding: 10px;
  font-size: 30px;
}

.close-modal-btn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 2px solid #666;
  border-radius: 5px;
  transition: transform .4s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 12px;
  right: 12px;
}

.input-wrap {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.modal-label {
  transition: transform .4s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.modal-input {
  width: 100%;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  outline: none;
  padding: 8px 8px 8px 16px;
  display: block;
}

.modal-input:focus ~ .modal-label, .modal-input:not(:placeholder-shown) ~ .modal-label {
  transform: translateY(-44px)translateX(-16px)scale(.9);
}

.modal-submit-btn.call-btn {
  width: 100%;
  border-color: rgba(0, 0, 0, 0);
  outline: none;
  margin: 0;
  padding: 8px;
  display: block;
}

.close-modal-btn:hover {
  transform: rotate(180deg);
}

.close-modal-btn:before {
  content: "";
  width: 2px;
  height: 16px;
  background-color: #666;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  transform: rotate(45deg);
}

.close-modal-btn:after {
  content: "";
  width: 2px;
  height: 16px;
  background-color: #666;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  transform: rotate(-45deg);
}

.modal-submit-btn-call.call-btn {
  color: var(--secondary-text-color);
  background-color: #0c9b07;
}

.modal-submit-btn-call.call-btn:hover {
  background-color: #11f309;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/*# sourceMappingURL=index.1a26ea67.css.map */
